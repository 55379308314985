<template>
  <div class="student-body-header">
    <div class="room-body-header d-f ai-c jc-sb">
      <div class="d-f ai-c jc-sb">
        <el-select v-model="educationVal" style="width: 100px; margin: 0 5px" placeholder="选择学历" clearable
          @clear="search">
          <el-option v-for="item in educationList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <!-- <el-select
          v-model="yearsVal"
          style="width: 100px; margin: 0 5px"
          placeholder="选择学年"
           @clear="search"
          clearable
        >
          <el-option
            v-for="item in yaersList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <my-input placeholder="请输入姓名、身份证、手机号" v-model="searchQuery" clearable style="width: 300px; margin-right: 8px" />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <el-button v-if="!isMM" title="导出数据" @click="exportData(false)" :style="!(sectionItem && sectionItem.length)
          ? 'cursor:not-allowed;opacity:.5'
          : 'cursor:pointer'
          " :disabled="!(sectionItem && sectionItem.length)">保存</el-button>

      </div>
      <div class="d-f ai-c">
        <common-daoru v-if="!isMM" title="批量导入" @click="importVisible = true" type="text" class="icon-btn-box"
          style="margin-left: 20px">
        </common-daoru>
        <!-- <el-button
          type="text"
          class="icon-btn-box"
          v-if="!isMM"
          title="导出数据"
          @click="exportData(false)"
          :style="
            !(sectionItem && sectionItem.length)
              ? 'cursor:not-allowed;opacity:.5'
              : 'cursor:pointer'
          "
          :disabled="!(sectionItem && sectionItem.length)"
        >
          <img class="icon-btn" src="@/assets/daochu.svg" alt="" srcset="" />
        </el-button> -->

        <common-daochu v-if="!isMM" title="全部导出" @click="exportData(true)" type="text" class="icon-btn-box"
          style="margin: 0 16px">
          <img style="" class="c-p icon-btn icon-btn-all" src="@/assets/daochuquanbu.svg" alt="" srcset="" />
        </common-daochu><span class="c-p" @click="assignFunc" style="font-size: 13px; margin-right: 12px">未分配学生总数：{{
          unStudentsTotal }}</span>
      </div>

      <div>
        <el-popover v-if="false" placement="top" width="400" trigger="click" popper-class="student-body-h" v-model="batDeletevisible">
          <div><b>批量删除学生信息</b></div>
          <el-form label-width="60px" style="margin-top:20px">
            <el-form-item label="年度:">
              <el-select v-model="batDelParams.enrolYear" clearable>
                <el-option v-for="item in yearsList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学院:">
              <el-select v-model="batDelParams.academyId" clearable>
                <el-option v-for="item in facultyList" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="batDeleteCancel">取消</el-button>

            <el-button :disabled="!(batDelParams.enrolYear || batDelParams.academyId)" type="primary"
              @click="batDeleteSure" size="mini">确定</el-button>

          </div>
          <el-button slot="reference">批量删除</el-button>
        </el-popover>
        <el-button v-if="!isMM" style="margin-left:10px" @click="batchDelVisible = true"
          :disabled="!(sectionItem && sectionItem.length)">删除</el-button>
        <el-button type="primaryHover" v-if="!isMM" @click="addStudents">添加学生</el-button>
        <!-- <el-button v-if="!isMM" @click="importVisible = true"
          >批量导入</el-button
        > -->
        <!-- <el-button
          v-if="!isMM"
          @click="batchDelVisible = true"
          :disabled="!(sectionItem && sectionItem.length)"
          >批量删除</el-button
        >
        <el-button type="primary" v-if="!isMM" @click="assignFunc"
          >自动分配宿舍</el-button
        > -->
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" custom-class="room-header-add" title="自动分配宿舍" :visible.sync="assignVisible"
      width="360px">
      <div class="c-9" style="margin-bottom: 12px">请选择分配方式</div>

      <el-radio v-model="assign" label="0">自由分配</el-radio>
      <el-radio v-model="assign" label="1">学院分配</el-radio>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="assignVisible = false">取消</el-button>
        <el-button type="primary" @click="accommodationSure">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" custom-class="room-header-add" title="批量删除" :visible.sync="batchDelVisible"
      width="360px">
      是否批量删除选中的{{ sectionItem ? sectionItem.length || 0 : 0 }}条数据？
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="batchDelVisible = false">取消</el-button>
        <el-button type="primary" @click="batchDeleteSure">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" custom-class="room-header-add-a" title="批量导入" :visible.sync="importVisible"
      width="560px" style="padding: 0">
      <el-alert v-if="filesName" :title="filesName" type="success" effect="dark" @close="filesName = ''"
        custom-class="dialog-import">
      </el-alert>
      <div v-else>
        <div class="upload-text-box d-f jc-sb ai-c">
          <b>
            批量导入学生信息
          </b>
          <el-button style="margin: 5px auto;width: 150px" @click="clickUpload('student')"><img style="width: 10px"
              src="@/assets/upload.png" alt="" />
            上传.xlsx文件</el-button>
          <el-button @click="downloadTemplate" type="text" style="width: auto">点击下载学生信息表模板</el-button>
        </div>
        <div class="upload-text-box d-f ai-c jc-sb">
          <b>
            批量调换学生宿舍
          </b>
          <el-button style="margin: 5px auto;width: 150px" @click="clickUpload('dorm')"><img style="width: 10px"
              src="@/assets/upload.png" alt="" />
            上传.xlsx文件</el-button>
          <el-button @click="downloadRoomTemplate" type="text" style="width: auto">点击下载宿舍调整表模板</el-button>
        </div>
        <input @change="fileChange" ref="file" type="file" style="display: block; width: 1px; height: 0; opacity: 0"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

        <div style="height: 100px"></div>
      </div>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog :close-on-click-modal="false" :visible.sync="errorMsgVisible" width="1500px" custom-class="other-dialog"
      top="147px">
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" />
        <span>{{
          "正确导入" +
          (errorMsg.total - errorMsg.errorNumber) +
          "条，错误信息" +
          errorMsg.errorNumber +
          "条"
        }}
        </span>
      </div>
      <el-table :data="errTableList" style="width: 100%">
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column v-for="item in errorCloumn" :key="item.prop" :prop="item.prop" :min-width="item.width"
          :label="item.label" :show-overflow-tooltip="!(editErr && item.prop != 'reason')">
          <template slot-scope="scope">
            <el-input v-if="editErr && item.prop != 'reason'" size="mini" v-model="scope.row[item.prop]" />
            <span v-else :style="item.prop == 'reason' ? 'color:red' : ''">{{
              scope.row[item.prop]
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="errorMsgVisible = false">取消</el-button>
        <el-button v-if="!editErr" @click="editErr = !editErr">修改错误信息</el-button>
        <el-button v-else @click="editErrFunc">确认修改并导入</el-button>

        <el-button type="primary" @click="exportErrMessage">保存错误信息</el-button>
      </div>
    </el-dialog> -->
    <student-body-add-student ref="addstudent" :title="'添加学生'" :visible.sync="drawer" @submit="addRoomFunc" />
    <err-excel ref="err" :showFixError="false" @reImport="importVisible = true" @exportErrMessage="exportErrMessage"
      :errCloumn="columns" :batAddFunction="batAddHostMaster" :getTableData="getTableList"></err-excel>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ErrExcel from "../common/errorExcel.vue";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("student");
import StudentBodyAddStudent from "./studentBodyAddStudent.vue";
export default {
  name: "room-body-header",
  components: {
    StudentBodyAddStudent,
    ErrExcel
  },
  data() {
    return {
      searchQuery: "",
      visible: false,
      drawer: false,
      educationVal: "",
      yearsVal: "",
      assignVisible: false,
      assign: "",
      importVisible: false,
      errorMsgVisible: false,
      errorMsg: {
        total: 32,
        errorNumber: 2,
      },
      errTableList: [],
      uuid: null,
      batDeletevisible: false,
      errorCloumn: [
        { prop: "studentName", label: "姓名", width: "80" },
        // { prop: "studentNo", label: "学号", width: "80" },
        { prop: "identityNo", label: "身份证", width: "150" },
        // { prop: "phone", label: "手机号码", width: "120" },
        { prop: "enrolYear", label: "入学年度", width: "100" },
        { prop: "academyName", label: "学院", width: "100" },
        { prop: "departmentName", label: "系", width: "100" },
        { prop: "buildingName", label: "楼栋", width: "80" },
        { prop: "roomNo", label: "房号", width: "80" },
        { prop: "reason", label: "错误原因", width: "300" },
      ],
      editErr: false, //  是否修改错误信息
      fileList: [],
      batchDelVisible: false,
      filesName: "",
      File: null,
      batDelParams: {
        academyId: "",
        enrolYear: ""
      },
      uploadType: "student", //student // dorm
      columns: [
        { label: "原房间号", prop: "oriRoomNo", width: "180" },
        { label: "现房间号", prop: "nowRoomNo", width: "180" },

      ],
    };
  },
  methods: {
    ...mapActions([
      "getStudentData",
      "batAdd",
      "addStudentInfo",
      "getStudentExcelTemplate",
      "uploadStudentExcelTemplate",
      "getStudentExcel",
      "batchDelStudent",
      "allocateStudentAccommodation",
      "getErrStudentExcel",
      "batAddStudents",
      "getStudentNumbers",
      "getUploadStatus",
      "finallyUploadFunc",
      "deleteBatStudent",
      "getErrRoomExcel",
      "uploadRoomExcelTemplate",
      "getRoomExcelTemplate"
    ]),
    ...mapMutations(["setStoreAddInfo", "setShowAcademy", "setLoading"]),
    clickUpload(type) {
      this.uploadType = type;
      this.$refs.file.click()
    },
    batDeleteCancel() {
      this.batDelParams = {
        academyId: "",
        enrolYear: ""
      }
      this.batDeletevisible = false
    },
    exportErrMessage(uuid) {
      this.getErrRoomExcel({ uuid });
    },
    batDeleteSure() {
      const { enrolYear, academyId } = this.batDelParams
      this.$confirm(`确定要删除${enrolYear ? enrolYear + '年度' : ''}${academyId ? this.facultyList.find(it => it.id == academyId).label : ''}的所有学生吗`, '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteBatStudent(this.batDelParams).then(res => {
          this.$message.success('批量删除成功')
        }).finally(() => {
          this.batDeleteCancel()
          this.getStudentData()
        })
      })
    },
    editErrFunc() {
      this.batAddStudents(this.errTableList).then((res) => {
        if (res.length) {
          this.errTableList = res;
          this.editErr = !this.editErr;
        } else {
          this.$message.success("批量修改成功！");
          this.errTableList = [];
          this.errorMsgVisible = false;
        }
      });
    },
    addStudents() {
      this.$refs.addstudent.init();
      this.drawer = true;
    },
    search() {
      this.getStudentData({
        degreeProperties: this.educationVal,
        enrolYear: this.yearsVal,
        complexCondition: this.searchQuery,
        pageNum: 1,
      }).then(() => {
        this.setShowAcademy(true);
      });
    },
    sure() {
      // 批量添加确认
      const fd = new FormData();
      fd.append("file", this.File);
      this.setLoading(true)
      if (this.uploadType == 'student') {
        this.uploadStudentExcelTemplate(fd)
          .then((res) => {
            return new Promise((resp, reje) => {
              // this.errorMsgVisible = true;
              console.log(res)
              const asyncTaskId = res.asyncTaskId
              if (asyncTaskId) {
                const animationFunc = window.requestAnimationFrame || function (fn) {
                  setTimeout(fn, 17);
                };
                let i = 0;
                const getUploadStatus = () => {
                  console.log(i)
                  if (i >= 60 * 5) {
                    this.getUploadStatus(asyncTaskId).then(response => {
                      if (response.taskStatus == '1') {
                        this.setLoading(false)
                        if (response.taskResult == 'success') {
                          resp()
                        } else { reje(asyncTaskId) }
                      } else {
                        i = 0;
                        animationFunc(getUploadStatus)
                      }
                    })
                  } else {
                    i++
                    animationFunc(getUploadStatus)
                  }
                }
                getUploadStatus()
              }
            })
          }, error => console.error(error)).then(() => {
            this.$message.success('导入成功')
            this.getStudentData();
          })
          .catch((asyncTaskId) => {
            this.$confirm('上传的文件中有错误，是否下载错误信息文件？', '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }
            ).then(() => {
              this.getErrStudentExcel({ asyncTaskId });
            }).finally(() => {
              this.setLoading(false)
              this.getStudentData();
            })




            // console.log(err);
            // const result = err.data.data;
            // this.uuid = result.uuid;
            // this.errorMsg = {
            //   total: result.listExcels.length,
            //   errorNumber: Object.keys(result.errorMap).length,
            // };
            // const list = result.listExcels.map((it) => ({ ...it, reason: [] }));
            // Object.values(result.errorMap).forEach((yy) => {
            //   yy.forEach((r) => {
            //     const item = list[r.row];
            //     item.reason.push(r.errorMsg);
            //   });
            // });

            // this.errTableList = list
            //   .filter((it) => it.reason && it.reason.length)
            //   .map((it) => ({
            //     ...it,
            //     reason: it.reason.join(","),
            //   }));
            // this.errorMsgVisible = true;
          });
      } else {
        this.uploadRoomExcelTemplate(fd).then(() => this.$message.success('导入成功')).catch((err) => this.$refs.err.getError(err)).finally(() => {
          this.setLoading(false)
        });
      }
      this.importVisible = false;
    },
    assignFunc() {
      this.assign = "";
      this.assignVisible = true;
    },
    addRoomFunc(data) {
      this.setStoreAddInfo(data);
      this.addStudentInfo(data).then(() => {
        this.drawer = false;
      });
    },
    downloadTemplate() {
      this.getStudentExcelTemplate();
    },
    downloadRoomTemplate() {
      this.getRoomExcelTemplate();
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.filesName = file.name;
      this.File = file;
    },
    exportData(all = false) {
      this.getStudentExcel(
        all
          ? 0
          : {
            exportIds: this.sectionItem.map((it) => it.id).join(","),
          }
      );
    },
    batchDeleteSure() {
      // 批量删除确认
      this.batchDelStudent(this.sectionItem.map((it) => it.id));
      this.batchDelVisible = false;
    },
    accommodationSure() {
      if (this.assign) {
        this.allocateStudentAccommodation(this.assign);
      }
      this.assignVisible = false;
    },
  },
  mounted() {
    this.getStudentNumbers();
  },

  watch: {
    querySearch(val) {
      this.educationVal = val.degreeProperties;

      this.searchQuery = val.complexCondition;
    },
    errorMsgVisible(val) {
      if (!val) {
        this.editErr = false;
      } else {
        this.getStudentData();
      }
    },
    educationVal(val) {
      if (val == "0 " || val) {
        this.search();
      }
    },
    yearsVal(val) {
      if (val == "0 " || val) {
        this.search();
      }
    },
    searchQuery(val) {
      if (!val) {
        this.search();
      }
    },
    importVisible() {
      this.filesName = "";
    },
  },
  computed: {
    ...mapState([
      "educationList",
      "yaersList",
      "sectionItem",
      "unStudentsTotal",
      "querySearch",
      "yearsList",
      "facultyList"
    ]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" >
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
  border: 1px solid #d7d8d9;
}

.room-header-add-a {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }

  .el-dialog__body {
    button {
      margin: 0 4px;
      width: 88px;
    }
  }

  .el-upload {
    width: 100%;
  }
}

.student-body-h {
  .el-radio {
    margin: 5px
  }
}

.upload-text-box {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;

}

.upload-text-box+.upload-text-box {
  border-top: none
}
</style>