<template>
  <span>
    <el-drawer :wrapperClosable="false" custom-class="room-add-room" :title="title || '添加房间'" :visible="drawer"
      :before-close="close" :direction="'rtl'" :size="350">
      <div class="add-room-body d-f">
        <div class="add-room-content">
          <div class="add-room-title require">姓名</div>
          <my-input v-model.trim="addStudent.studentName" :readonly="readOnly" @focus="cancelReadOnly"
            placeholder="请填写姓名" />
          <div class="add-room-title">性别</div>
          <el-select v-model="addStudent.ssex" style="width: 100%" placeholder="请选择性别">
            <el-option value="0" label="男">

            </el-option>
            <el-option value="1" label="女">

            </el-option>
          </el-select>
          <div class="add-room-title ">身份证号码</div>
          <el-input v-model="addStudent.identityNo" placeholder="请填写身份证号码" />
          <div class="add-room-title">手机号码</div>
          <el-input v-model="addStudent.phone" type="phone" placeholder="请填写手机号码" />
          <div class="add-room-title require">入学年度</div>
          <el-date-picker v-model="addStudent.enrolYear" type="year" style="width: 100%" placeholder="选择入学年度"
            value-format="yyyy">
          </el-date-picker>
          <div class="add-room-title require">院系</div>
          <el-select v-model="addStudent.academyId" style="width: 100%" placeholder="请选择院系" @change="academyIdChange">
            <el-option v-for="group in facultyList" :key="group.label" :label="group.label" :value="group.id">
            </el-option>
          </el-select>
          <div v-if="departList.length" class="add-room-title require">系</div>
          <el-select v-if="departList.length" v-model="addStudent.departmentId" style="width: 100%" placeholder="请选择系"
            @change="departmentChange">
            <el-option v-for="item in departList" :key="item.label" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
          <!-- <div class="add-room-title">学号</div>
          <el-input v-model="addStudent.studentNo" placeholder="请填写学号" /> -->
          <div class="add-room-title">楼栋</div>
          <el-select clearable v-model="addStudent.buildingId" style="width: 100%" placeholder="请选择楼栋"
            @change="buildChange">
            <el-option v-for="item in buildList" :key="item.label" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
          <div class="add-room-title">房号</div>
          <el-select v-model="addStudent.roomId" style="width: 100%" placeholder="请选择房号" @change="roomChange" filterable
            clearable>
            <el-option v-for="item in roomList" :key="item.label" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
          <div class="add-room-title">学历属性</div>
          <el-select v-model="addStudent.degreeProperties" style="width: 100%" placeholder="选择学历">
            <el-option v-for="item in educationList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="add-room-submit w-100_ d-f ai-c">
          <el-button type="primary" @click="addRoomFunc">保 存</el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-drawer>
  </span>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("student");
export default {
  name: "room-body-add-room",
  data() {
    return {
      readOnly: true,
      drawer: false, //  抽屉
      addStudent: {
        studentName: "",
        studentNo: "",
        identityNo: "", //
        ssex: "0",
        enrolYear: "",
        academyId: "",
        departmentId: "",
        buildingId: "",
        roomId: "",
        degreeProperties: "",
        phone: "",
      },
      loading: false,
      options: [],
      addStudentShow: false,
      studentQuery: "", // 搜索学生内容
      total: 0,
      pageSize: 10,
      current: 1,
      batchStudent: [], // 批量学生列表篇
      departList: [],
    };
  },
  methods: {
    ...mapActions(["searchStudentFux", "getRoomInfo"]),
    ...mapMutations(["setStudentList"]),
    cancelReadOnly() {
      this.readOnly = false
    },
    batchAddd() {
      this.addStudentShow = false;

      ///  选中学生
    },
    handleSelectionChange(item) {
      // 选中学生
      console.log(item);
      this.batchStudent = item;
    },
    searchStudent() {
      //  搜索学生
      if (this.studentQuery) {
        //
        const { pageSize, current, studentQuery } = this;
        this.searchStudentFux({
          pageSize,
          current,
          studentQuery,
        });
      }
    },
    currentChange(page) {
      //    页码切换
      this.current = page;
    },
    close() {
      this.$emit("update:visible", false);
    },
    phoneConfirm() {
      if (this.data?.phone && this.data?.phone != this.addStudent.phone) {
        return this.$confirm("原手机号【" + this.data?.phone + "】将不能开锁", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",

        })
      }
      return Promise.resolve()
    },
    addRoomFunc() {
      this.phoneConfirm().then(() => {
        const warning = {
          studentName: "请输入学生姓名",
          // identityNo: "请输入身份证号码", //
          enrolYear: "请选择入学年度",
          academyId: "请选择学院",
          // departmentId: "请选择系",
          // studentNo: "请输入学生学号",
          // phone: "请填写学生手机号码",
        };
        this.departList.length && (warning.departmentId = "请选择系");
        const list = Object.keys(warning);
        let canIgo = true;
        for (let i = 0; i < list.length; i++) {
          if (!String(this.addStudent[list[i]])) {
            this.$message.error(warning[list[i]]);
            canIgo = false;
            break;
          }
        }
        // if (
        //   !/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(
        //     this.addStudent.identityNo
        //   )
        // ) {
        //   this.$message.error("请输入正确的身份证号码");
        //   return;
        // }
        // if (
        //   !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        //     this.addStudent.phone
        //   )
        // ) {
        //   this.$message.error("请输入正确的手机号");
        //   return;
        // }
        if (canIgo) {
          this.$emit("submit", {
            ...this.addStudent,
            roomId: this.addStudent.roomId || 0,
            buildingId: this.addStudent.buildingId || 0,
            academyId:
              this.addStudent.academyId || (this.data ? this.data.academyId : ""),
            departmentId:
              this.addStudent.departmentId ||
              (this.data ? this.data.departmentId : ""),
            degreeProperties:
              this.addStudent.degreeProperties ||
              (this.data ? this.data.degreeProperties : ""),
          });
        }
      })
    },
    academyIdChange(id) {
      this.addStudent.departmentId = null;
      this.addStudent.departmentName = null;
      for (let item of this.facultyList) {
        if (item.id == id) {
          this.addStudent.academyName = item.label;
          this.departList = item.children.filter((it) => !it.type);
          break;
        }
      }
    },
    departmentChange(id) {
      for (let item of this.departList) {
        if (item.id == id) {
          this.addStudent.departmentName = item.label;
          break;
        }
      }
    },
    buildChange(id) {
      this.addStudent.roomId = '';
      if (!id) {
        this.addStudent.buildingName = ""
        return
      };
      this.getRoomInfo(id);
      for (let item of this.buildList) {
        if (item.id == id) {
          this.addStudent.buildingName = item.label;
          break;
        }
      }
    },
    roomChange(id) {
      this.addStudent.roomNo = "";
      if (!id) {
        // this.addStudent.roomId = 0;
        return;
      }
      for (let item of this.roomList) {
        if (item.id == id) {
          this.addStudent.roomNo = item.label;
          break;
        }
      }
    },
    remoteMethod(query) {
      // 远程搜索学生信息
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = [];
        }, 800);
      } else {
        this.options = [];
      }
    },
    init() {
      this.addStudent = {
        ssex:"0",
        enrolYear: "",
        academyId: "",
        departmentId: "",
        buildingId: "",
        roomId: "",
        degreeProperties: "",
        studentNo: "",
        ...this.storeAddInfo,
        // phone: "",
        studentName: "",
        identityNo: "", // 床位
      };
    },
  },
  mounted() {
    // this.getRoomInfo();
  },
  watch: {
    drawer(val) { },
    visible(val) {
      this.drawer = val;
    },
    addStudentShow(val) {
      if (!val) {
        this.studentQuery = ""; // 搜索学生内容
        this.total = 0;
        this.pageSize = 10;
        this.current = 1;
        this.setStudentList([]);
      }
    },
    data(val) {
      if (val) {
        this.addStudent = val ? { ...val } : {};
        this.addStudent.academyId = this.addStudent.academyId || "";
        this.addStudent.departmentId = this.addStudent.departmentId || "";
        this.addStudent.degreeProperties =
          this.addStudent.degreeProperties || "";
        for (let item of this.facultyList) {
          if (item.id == val.academyId) {
            this.departList = item.children.filter((it) => !it.type);
            break;
          }
        }
        this.getRoomInfo(val.buildingId);
      }
    },
  },
  computed: {
    ...mapState([
      "facultyList",
      "educationList",
      "roomList",
      "buildList",
      "storeAddInfo",
    ]),
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
};
</script>

<style lang="scss" scoped></style>