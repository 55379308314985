<template>
  <div class="room-body h-100_">
    <student-body-header />
    <div class="room-body-content w-100_">
      <common-table ref="table" short :data="tableList" style="width: 100%" @selectionChange="handleSelectionChange"
        :setQuerySearchPagesize="setQuerySearchPagesize" :setQuerySearchPageNum="setQuerySearchPageNum"
        :querySearch="querySearch" :queryTableData="!$route.query.hadData && getStudentData" :total="total">
        <el-table-column type="selection" v-if="!isMM" width="55" align="center">
        </el-table-column>
        <el-table-column align="left" label="序号" type="index" width="100">
          <template slot-scope="scope">
            {{
              (querySearch.pageNum - 1) * querySearch.pageSize +
              1 +
              scope.$index
            }}
          </template>
        </el-table-column>
        <el-table-column prop="studentName" show-overflow-tooltip width="100" label="姓名" align="left">
        </el-table-column>
        <!-- <el-table-column
          prop="studentNo"
          show-overflow-tooltip
          width="80"
          label="学号"
          align="left"
        >
        </el-table-column> -->
        <el-table-column width="80" show-overflow-tooltip prop="ssex" label="性别" align="left">
          <template slot-scope="scope">
            {{ ["男", "女", "保密"][scope.row.ssex] }}
          </template>
        </el-table-column>
        <el-table-column v-if="showAcademy" prop="academyName" show-overflow-tooltip width="200" label="院系" align="left">
          <template slot-scope="scope">
            {{ scope.row.academyName }}
            {{ scope.row.departmentName ? "/" + scope.row.departmentName : "" }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="departmentName"
          show-overflow-tooltip
          width="120"
          label="系"
          align="left"
        >
        </el-table-column> -->
        <el-table-column prop="enrolYear" show-overflow-tooltip width="80" label="入学年度" align="left">
        </el-table-column>
        <el-table-column prop="identityNo" show-overflow-tooltip width="170" wid label="身份证号码" align="left">
        </el-table-column>
        <el-table-column prop="phone" show-overflow-tooltip width="120" label="手机号码" align="left">
        </el-table-column>
        <el-table-column prop="buildingName" show-overflow-tooltip width="80" align="left" label="楼栋号">
        </el-table-column>
        <el-table-column prop="roomNo" show-overflow-tooltip width="80" align="left" label="房号">
          <template slot-scope="{row}">
            <span style="color: #409eff;cursor: pointer;" @click="goToRoom(row.roomId)">{{ row.roomNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="degreeProperties" show-overflow-tooltip width="80" label="学历属性" align="left">
          <template slot-scope="scope">
            {{ ["专科", "本科", "硕士", "博士", "无"][scope.row.degreeProperties] }}
          </template>
        </el-table-column>
        <el-table-column prop="identificationStatus" show-overflow-tooltip label="认证状态" align="left">
          <template slot-scope="scope">
            {{ scope.row.identificationStatus == 0 ? "未认证" : "已认证" }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="left" width="100" prop="address" label="操作" v-if="!isMM">
          <template slot-scope="scope">
            <div class="d-f ai-c jc-sa set">
              <img title="修改" src="@/assets/edit.svg" class="c-p el-icon-edit-outline" @click="edit(scope.row)" />
              <el-popconfirm v-if="scope.row.userId"  @confirm="resetConfirm(scope.row)" :title="`确定要重置[${scope.row.studentName}]的密码吗？`">
                <img title="重置" slot="reference" src="@/assets/reset.svg" class="c-p el-icon-edit-outline" />
              </el-popconfirm>
              <img title="不允许重置" v-else  src="@/assets/reset.svg" class="c-p el-icon-edit-outline" style="cursor:not-allowed" />
              <img title="删除" src="@/assets/delete.svg" class="el-icon-delete c-p" @click="deleteF(scope.row)" />
            </div>
          </template>
        </el-table-column>
      </common-table>
    </div>
    <student-body-add-student :visible.sync="showEdit" @submit="finishEdit" title="编辑学生信息" :data="editData" />
    <cell-set :deleteVisible.sync="deleteVisible" :item="currentItem" @deleteSure="deleteFunc" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("student");
const { mapMutations: roomMutations } = createNamespacedHelpers("room");
import StudentBodyHeader from "./studentBodyHeader.vue";
import studentBodyAddStudent from "./studentBodyAddStudent.vue";
import CellSet from "./cellSet.vue";

export default {
  name: "room-body",
  components: {
    StudentBodyHeader,
    studentBodyAddStudent,
    CellSet,
  },
  data() {
    return {
      showEdit: false,
      deleteVisible: false,
      currentItem: null,
      current: 1,
      editData: null,
      pageSize: 20,
      s: null,
    };
  },
  methods: {
    ...mapActions([
      "getStudentData",
      "getBuildInfo",
      "editStudentData",
      "deleteStudent",
      "resetStudentPass"
    ]),
    ...mapMutations([
      "setSectionItem",
      "initQuery",
      "setQuerySearchPagesize",
      "setQuerySearchPageNum",
      "setShowAcademy",
    ]),
    ...roomMutations(["setCheckBuildInfo"]),
    resetConfirm(row) {
      console.log(row)
      this.resetStudentPass(row.userId).then(() => {
        this.$message.success('重置成功')
      }).catch(() => {
        this.$message.success('重置失败')
      })
    },
    deleteFunc(item) {
      //   删除
      this.deleteStudent(item.id);
      this.deleteVisible = false;
    },
    modify(val) {
      //  修改
      console.log(val);
      this.modifyVisible = false;
    },
    finishEdit(data) {
      //  编辑完成获取数据；
      this.editStudentData(data).then(() => {
        this.showEdit = false;
      });
    },
    goToRoom(roomId) {
      this.setCheckBuildInfo({ id: roomId })
      this.$router.push('/room')

    },
    edit(row) {
      // 编辑当前数据\
      this.editData = {
        ...row,
        buildingId: row.buildingId || "",
        roomId: row.roomId || "",
        departmentId: row.departmentId == -1 ? "" : row.departmentId,
      };
      this.showEdit = true;
    },
    deleteF(row) {
      // 删除当前数据
      this.currentItem = { ...row };
      this.deleteVisible = true;
    },

    handleSelectionChange(val) {
      // 多选的item
      this.setSectionItem(val);
    },
  },
  mounted() {
    console.log(this.$route.query)
    this.getBuildInfo();
    this.setSectionItem(null);
    this.$nextTick(() => {
      this.setShowAcademy(true);
    });
  },
  beforeDestroy() {
    this.initQuery();
  },
  computed: {
    ...mapState([
      "tableList",
      "chooseType",
      "total",
      "querySearch",
      "sectionItem",
      "showAcademy",
    ]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped></style>
