<template>
  <div class="tree-check-item c-p p-r" :style="('max-height:' + (open ? itemHeight : 40) + 'px')">
    <div @click="clickHeader"
      :style="(headerBack ? 'background: rgb(240, 240, 240);;' : '') + ';padding-left:' + (cellNumber + 1) * 12 + 'px'"
      class="title d-f jc-sb ai-c fw-b">
      {{ label }}
      <img :style="open && 'transform: rotate(180deg);'" :class="'el-icon-arrow-down'" src="@/assets/arr-down.svg" />
    </div>

    <template v-for="(item, index) in list">
      <tree-checke-item :usedBySetting="usedBySetting" :indexCell="indexCell + '-' + index"
        :showBackgroundArr="showBackgroundArr" :backValue="backValue" :key="item.id + item.label"
        v-if="item.children && item.children.length" :label="item.label" :cell="cell + 1" :idx="item.id + ''"
        :list="item.children" :cellNumber="cellNumber + 1" :parentIdx="idx"
        @backShowBackground="val => backShowBackground(val, index)"
        :headerBack="showBackgroundArr[cell + 1] == indexCell + '-' + index" />
      <div v-else @click="clickItem(item.id, cellNumber, index)" :style="(showBackgroundArr[cell + 1] == indexCell + '-' + index ? 'background:rgb(240, 240, 240)' : '') +
        (';padding-left:' + (cellNumber + 1) * 16 + 'px;' + (!index ? ' margin-top:5px' : ''))" class=" c-p item fw-b d-f
      ai-c" :key="item.id + item.label + 4">
        {{ item.label }}
      </div>
    </template>
    <cell-set :modifyVisible.sync="modifyVisible" :deleteVisible.sync="deleteVisible" :modifyName="modifyName"
      @modify="modify" @deleteSure="deleteFunc" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("student");
import CellSet from "./cellSet.vue";
export default {
  name: "tree-checke-item",
  components: {
    CellSet,
  },
  data() {
    return {
      showBackgroundIndex: null,
      open: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
    };
  },
  props: {
    idx: String,
    indexCell: String,
    label: String,
    cellNumber: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
    cell: Number,
    parentIdx: String,
    usedBySetting: Boolean,
    backValue: Function,
    showBackgroundArr: {
      type: Array,
      default: () => []
    },
    headerBack: Boolean
  },
  mounted() {
    console.log(this.cell)
  },
  methods: {
    ...mapActions(["getCellData", "getStudentData"]),
    ...mapMutations(["setShowAcademy", "setcheckedRoom"]),
    deleteFunc() {
      //   删除
      this.deleteVisible = false;
    },
    modify(val) {
      //  修改
      console.log(val);
      this.modifyVisible = false;
    },
    backShowBackground(arr, index) {
      console.log(arr, index)
      this.$emit('backShowBackground', [this.indexCell + '-' + index, ...arr])
    },
    command(item) {
      if (item == "modify") {
        this.modifyName = this.label;
        this.modifyVisible = true;
      }
      if (item == "delete") {
        this.deleteVisible = true;
      }
    },
    clickHeader() {
      this.open = !this.open;
      if (this.cell) {
        this.getStudentData({
          departmentId: this.idx,
          academyId: this.parentIdx,
          enrolYear: "",
          pageNum: 1
        }).then(() => {
          this.setShowAcademy(false);
        });
      } else {
        this.getStudentData({
          academyId: this.idx,
          departmentId: "",
          enrolYear: "",
          pageNum: 1
        }).then(() => {
          this.setShowAcademy(false);
        });
      }
      this.$emit('backShowBackground', [this.indexCell])
    },
    clickItem(year, idx, index) {
      this.$emit('backShowBackground', [this.indexCell + '-' + index])
      this.setcheckedRoom(year + idx);
      const l = idx ? "departmentId" : "academyId";
      const params = {
        academyId: this.parentIdx,
        [l]: this.idx,
        enrolYear: year,
        pageNum: 1
      };
      console.log(params)
      // this?.backValue(params)
      this.getStudentData(params).then(() => {
        this.setShowAcademy(false);
      });
    },
    getlength(list) {
      return list.reduce(
        (c, n) => c + (n.children ? this.getlength(n.children) + 1 : 1),
        0
      );
    },
  },
  computed: {
    ...mapState(["checkedCell", "checkedRoom", "querySearch"]),
    itemHeight() {
      return this.getlength(this.list) * 40 + 45;
    },
  },
  watch: {
    showBackgroundArr(val) {
      console.log('dddd', val, this.cell)
    }
  }
};
</script>

<style lang="scss" scoped>
.tree-check-item {
  transition: all 0.3s;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);

  .title {
    padding: 0 12px 0 16px;
    height: 40px;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

    i {
      transition: all 0.3s;
    }

  }

  .item {
    height: 40px;
    padding: 0 12px 0 32px;
    font-size: 13px;



    &:hover {
      background: rgb(240, 240, 240);
      ;
    }
  }



  .item+.item {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
  }
}
</style>