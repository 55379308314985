<template>
  <span>
    <el-dialog
      top="275px"
      :close-on-click-modal="false"
      width="360px"
      :visible.sync="deleteVisible"
      :before-close="closeDelete"
    >
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" alt="" />
        批量删除信息确认
      </div>
      你确定要删除<b
        >{{ item && item.academyName }}-{{ item && item.departmentName }}-{{
          item && item.studentName
        }}</b
      >的信息吗?
      <div slot="footer">
        <el-button @click="closeDelete">取 消</el-button>
        <el-button type="primary" @click="deleteFunc">确 定</el-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
export default {
  data() {
    return {
      modifyVal: "",
    };
  },
  methods: {
    modify() {
      this.closeModify();
      this.$emit("modify", this.modifyVal);
    },
    deleteFunc() {
      this.closeDelete();
      this.$emit("deleteSure", this.item);
    },
    closeModify() {
      this.$emit("update:modifyVisible", false);
    },
    closeDelete() {
      this.$emit("update:deleteVisible", false);
    },
  },
  watch: {
    modifyName(val) {
      this.modifyVal = val;
    },
  },
  props: {
    deleteVisible: Boolean,
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>