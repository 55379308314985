<template>
  <div class="tree-check h-100_ d-f">
    <div @click="openAll" :style="checkedCell == -1 && 'background:#f0f0f0'" class="tree-header c-p d-f jc-sb ai-c fw-b">
      全部
      <i :style="open && 'transform: rotate(180deg);'" class="el-icon-arrow-down"></i>
    </div>
    <div style="flex: 1" class="w-100_ tree-check-box">
      <tree-check-item :usedBySetting="usedBySetting" :showBackgroundArr="showBackgroundArr"
        @backShowBackground="arr => backShowBackground(arr, index)" v-for="(item, index) in facultyList"
        :key="item.id + index" :label="item.label" :idx="item.id + ''" :headerBack="showBackgroundArr[0] == index"
        :list="item.children" :cell="0" :indexCell="index">
      </tree-check-item>
    </div>
    <!-- <el-button @click="showAdd = true" type="primary" class="w-100_"
      >添加学院/系</el-button
    > -->
    <!-- <room-body-add-cell :visible.sync="showAdd" :submit="addCell" /> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("student");
import TreeCheckItem from "./treeCheckItem.vue";
// import RoomBodyAddCell from "./roomBodyAddCell.vue";
export default {
  name: "tree-check",
  props: {
    usedBySetting: Boolean
  },
  data() {
    return {
      activeNames: [],
      open: false,
      showAdd: false,
      showBackgroundArr: []
    };
  },
  components: { TreeCheckItem },
  methods: {
    ...mapActions([
      "getFacultyList",
      "getAllData",
      "getAdacyList",
      "getOrgList",
      "getStudentData",
    ]),

    ...mapMutations(["initQuery", "setShowAcademy", "setCheckedCell"]),
    backShowBackground(val, index) {
      this.showBackgroundArr = [index, ...val]
      console.log(this.showBackgroundArr)
    },
    handleChange(val) {
      // 折叠面板改变事件
      console.log(val);
    },
    openAll() {
      //  点击全部的操作
      this.setCheckedCell(-1);
      this.showBackgroundArr = []
      this.open = !this.open;
      // if (this.open) {
      if (this.usedBySetting) return;
      this.initQuery();
      this.getStudentData({pageNum: 1}).then((res) => {
        this.setShowAcademy(true);
      });
      // }
      this.$nextTick(() => {
        this.$children.forEach((vm) => {
          vm.open = this.open;
        });
      });
    },
    addCell(data) {
      console.log(data); // 新增楼栋
    },
  },
  mounted() {
    // this.getFacultyList();
    this.getAdacyList(); //  获取院系
    this.getOrgList();
  },
  computed: {
    ...mapState({
      facultyList: (state) => state.facultyList,
      chooseType: (state) => state.chooseType,
    }),
  },
};
</script>

<style lang="scss" scoped>
.tree-check {
  min-width: 212px;
  background: #fff;
  flex-direction: column;
  border: 1px solid #d7d8d9;

  .tree-header {
    line-height: 45px;
    padding: 0 12px 0 16px;
    border-bottom: 1px solid #d7d8d9;

    i {
      transition: all 0.3s;
    }
  }

  .tree-check-box {
    overflow: auto;
  }
}
</style>