<template>
  <div class="student w-100_ h-100_ d-f  ai-c" >
    <tree-check />
    <student-body />
  </div>
</template>
<script>
import "./index.scss";
import TreeCheck from "./treeCheck.vue";
import StudentBody from "./studentBody.vue";

export default {
  name: "room",
  components: {
    TreeCheck,
    StudentBody,
  },
 

};
</script>
<style lang="scss" scoped></style>